/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { JsonToTable } from 'react-json-to-table'
import '../../VodafoneIdea.scss'
import SectionHeader from '../../vilBranding/SectionHeader'
import TermsConditionNew from '../../termsCondition'
import './IllCrfForm.css'
import {
	useExcelJsonList,
	useLoadRomsFormFieldListData,
	useLoadFieldValues,
	useLoadCompanySeal,
} from '../../../../../../hooks/form.hooks'
import { loadImageBlob } from '../../../../../../utils/aws.util'
import { useAuthContext } from '../../../../../../context/auth.context'
import { TERMS_VERSION, getVersionTerms, encode } from '../../cafCrf.util'
import { Spinner, Center, useTheme } from '@chakra-ui/react'

const IllCrfForm = ({ formData, queueData, romsActivity, submittedForms }) => {
	const [CrfData, setCrfData] = useState(false)
	const [illCrfDataupdated, setUpdatedCrfData] = useState(null)
	const [companySeal, setCompanySeal] = useState(null)
	const [photoGraph, setPhotograph] = useState(null)
	const [authorizedSignatoryName, setAuthSignName] = useState(null)
	const [imageData, setImageData] = useState(null)
	const [imageType, setImageType] = useState(null)
	const [acctMngSignImageData, setacctMngSignImageData] = useState(null)
	const [acctMngSignImageType, setacctMngSignImageType] = useState(null)
	const [photoGraphImageData, setphotoGraphImageData] = useState(null)
	const [photoGraphImageType, setphotoGraphImageType] = useState(null)
	const [excelJson, setexcelJson] = useState(null)

	const { mutate } = useLoadRomsFormFieldListData()
	const { mutate: loadOrderFormValues } = useLoadFieldValues()
	const { mutate: loadExcelJsonValues } = useExcelJsonList()
	const { mutate: loadCompanySeal } = useLoadCompanySeal()
	let componentRef = React.useRef()
	const chakraTheme = useTheme()

	const {
		state: { authData },
	} = useAuthContext()

	const CustomComponent = () => {
		return (
			<div className='section3 font8'>
				<div className=''>
					<div className='pad_b5'>
						<p className='customfom'>Product Form - Internet Leased Line</p>
					</div>
					<p className='ligthbg font8'>
						ILL Service access under ISP License Terms &amp; Conditions
					</p>
					<p className='font10'>
						<strong>
							This "Change Request Form" (CRF) shall mean together (i) the
							customer information form; and (ii) the following conditions
							("Regulatory Terms"), which are required to be provided/completed
							with effect from /or prior to activation of Internet Leased Line
							Service provided by Vodafone Idea Limited under its ISP- All India
							(Cat A) License and these 'Regulatory Terms' shall continue during
							the term Of service.
						</strong>
					</p>
					<p className='regtext'>
						Regulatory terms governing mpls vpn leased line services
					</p>
				</div>
			</div>
		)
	}

	const fetchFieldListData = () => {
		mutate(
			{
				fieldList: {
					organization_id: authData.organization_id,
					account_id: authData.account_id,
					workforce_id: authData.workforce_id,
					form_id: formData.form_id,
				},
				fieldValues: {
					form_id: formData.form_id,
					form_transaction_id: formData.form_transaction_id
						? formData.form_transaction_id
						: formData.data_form_transaction_id,
					start_from: 0,
					limit_value: 50,
				},
			},
			{
				onSuccess: async data => {
					let finalFields = data
					setCrfData(false)
					setUpdatedCrfData(finalFields)
				},
				onError: async err => {
					console.log('error occurred while loading fields', err)
				},
			}
		)
	}

	const fetchOrderFormValues = () => {
		let orderForm = submittedForms.find(
			item => item.form_id === romsActivity.order_form_id
		)
		loadOrderFormValues(
			{
				form_id: romsActivity?.order_form_id,
				form_transaction_id: !!orderForm
					? orderForm.data_form_transaction_id
					: 0,
			},
			{
				onSuccess: async res => {
					if (res.length > 0) {
						let ExcelIndex = res[0].activity_inline_data.findIndex(
							res1 => res1.field_id === 12891
						)
						let excelUrl
						if (ExcelIndex > -1) {
							excelUrl = res[0].activity_inline_data[ExcelIndex].field_value
						}
						if (!!excelUrl) {
							loadExcelJsonValues(
								{ bucket_url: excelUrl },
								{
									onSuccess: async result => {
										setexcelJson(JSON.parse(result))
									},
								}
							)
						}
					}
				},
			}
		)
	}

	const fetchCompanySeal = () => {
		loadCompanySeal(
			{
				form_id: 1306,
				field_id: 0,
				activity_id: queueData.activity_id,
			},
			{
				onSuccess: async res => {
					let companySeal = ''
					let authorizedSignatoryName = ''
					let data = []
					if (res.length > 0) {
						data = JSON.parse(res[0].data_entity_inline).form_submitted
					} else {
						data = []
					}
					data.forEach(function (fieldObj, index) {
						if (Number(fieldObj.field_id) === 11184) {
							companySeal = fieldObj.field_value
						}
						if (Number(fieldObj.field_id) === 11183) {
							authorizedSignatoryName = fieldObj.field_value
						}
					})
					setCompanySeal(companySeal)
					setAuthSignName(authorizedSignatoryName)
				},
			}
		)
	}

	useEffect(() => {
		setCrfData(true)
		fetchCompanySeal()
		fetchFieldListData()
		fetchOrderFormValues()
	}, [])

	useEffect(() => {
		let data = illCrfDataupdated
		if (!!data) {
			if (!!companySeal) {
				// customer company seal
				let custCompanySeal = companySeal
				loadImageBlob(custCompanySeal, (data, type) => {
					setImageData(data)
					setImageType(type)
				})
			}
			if (!!data[9749].value) {
				// manager sign url
				let acctManagerSignUrl = data[9749].value
				loadImageBlob(acctManagerSignUrl, (data, type) => {
					setacctMngSignImageData(data)
					setacctMngSignImageType(type)
				})
			}
		}
	}, [illCrfDataupdated, companySeal, photoGraph, authorizedSignatoryName])

	let crfContentView = ''
	if (!!illCrfDataupdated) {
		let firstCaps = ''
		let secondBold = ''
		let Signcss
		if (!!authorizedSignatoryName) {
			let customerSignSplit = authorizedSignatoryName.split(' ')

			if (customerSignSplit.length > 1) {
				firstCaps = customerSignSplit['0'].slice(0, 1).toUpperCase()
				secondBold = customerSignSplit['1']
				Signcss = 'signate_img input_sign'
			} else {
				firstCaps = customerSignSplit['0']
				secondBold = ''
				Signcss = 'signate_img input_sign under_line'
			}
		}

		crfContentView = (
			<div>
				<div
					style={{ textAlign: 'left' }}
					className='tab_content'
					ref={el => (componentRef = el)}
				>
					<page size='A4'>
						<div className='section2'>
							<div className='font8'>
								<SectionHeader name='ILL Change Request Form' />
								<div className='pad_20t'>
									<div className='over_hid'>
										<div className='pd_t5'>
											<div className='caf'>
												CAF ID
												<input
													className='form_col'
													type='text'
													name=''
													value={illCrfDataupdated[9452].value}
													readOnly
												/>
											</div>
										</div>
									</div>
									<div className='over_hid mar_t-5'>
										<div className='pd_t5'>
											<strong>Customer Account Code:</strong>{' '}
											<span className='pad_l10'>
												<input
													className='form_col_input'
													type='text'
													name=''
													value={illCrfDataupdated[9453].value}
													readOnly
												/>
											</span>{' '}
										</div>
									</div>
								</div>
								<div className='over_hid auth'>
									<div className='fleft widthfull'>
										Customer Name
										<input
											className='form_col flright width81'
											type='text'
											name=''
											value={illCrfDataupdated[9454].value}
											readOnly
										/>
									</div>
								</div>
								<div className='over_hid pad_t10'>
									<div className='fleft width45 textcenter'>
										{' '}
										Feasibility ID (FR ID){' '}
									</div>
									<div className='fleft width45 textcenter caf'>
										<input
											className='form_col width50'
											type='text'
											name=''
											value={illCrfDataupdated[9455].value}
											readOnly
										/>
									</div>
								</div>
								<div className='over_hid pad_5t'>
									<div className='fleft width45 textcenter'>
										{' '}
										Please mention circuit id.
									</div>
									<div className='fleft width45 textcenter caf'>
										<input
											className='form_col width50'
											type='text'
											name=''
											value={illCrfDataupdated[9456].value}
											readOnly
										/>
									</div>
								</div>
								<div className='over_hid pad_5t'>
									<div className='fleft width45 textcenter'>
										Please mention VOX ID
									</div>
									<div className='fleft width45 textcenter caf'>
										<input
											className='form_col width50'
											type='text'
											name=''
											value={illCrfDataupdated[9457].value}
											readOnly
										/>
									</div>
								</div>
								<div className='over_hid pad_t15'>
									<div className='fleft width72px'> Type of Order</div>
									<div className='fleft width45 pad_l5per'>
										<div className='over_hid'>
											<div className='fleft width80 pad_t2'>
												{' '}
												Company Name Change{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Company Name Change') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												{' '}
												Customer Contact Change{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Customer Contact Change') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												{' '}
												Billing Address change{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Billing Address change') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												{' '}
												CIR Bandwidth Upgrade -same interface{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('CIR Bandwidth Upgrade -same interface') >=
												0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												{' '}
												CIR Bandwidth Upgrade with interface change{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf(
														'CIR Bandwidth Upgrade with interface change'
													) >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												{' '}
												CIR Bandwidth Downgrade- Same Interface{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('CIR Bandwidth Downgrade- Same Interface') >=
												0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												{' '}
												CIR Bandwidth Downgrade- Interface change{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf(
														'CIR Bandwidth Downgrade- Interface change'
													) >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												Interface Change{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Interface Change') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												{' '}
												Interface Change- Media Change{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Interface Change- Media Change') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												{' '}
												Interface Change- LM provider change{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Interface Change- LM provider change') >=
												0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												Change in Protocol
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Change in Protocol') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												Change in CPE - Add/ Remove
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Change in CPE - Add/ Remove') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												{' '}
												Additional WAN IP{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Additional WAN IP') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												{' '}
												Additional LAN IP{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Additional LAN IP') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>VoIP- Enable </div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('VoIP- Enable') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>VoIP- Disable </div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('VoIP- Disable') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>Site Shifting </div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Site Shifting') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												Billing Periodicity Change{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Billing Periodicity Change') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
											<div className='over_hid pad_t2'>
												<div className='fleft width80 pad_t2'>
													Change of ILL variant (Flavour){' '}
												</div>
												<div className='fleft'>
													{illCrfDataupdated[9458].value
														.split('|')
														.indexOf('Change of ILL variant (Flavour)') >= 0 ? (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															checked
															readOnly
														/>
													) : (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															disabled
														/>
													)}
												</div>
											</div>
											<div className='over_hid pad_t2'>
												<div className='fleft width80 pad_t2'>
													ToD - Subscribe ToD{' '}
												</div>
												<div className='fleft'>
													{illCrfDataupdated[9458].value
														.split('|')
														.indexOf('ToD - Subscribe ToD') >= 0 ? (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															checked
															readOnly
														/>
													) : (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															disabled
														/>
													)}
												</div>
											</div>
											<div className='over_hid pad_t2'>
												<div className='fleft width80 pad_t2'>
													ToD - UnSubscribe ToD{' '}
												</div>
												<div className='fleft'>
													{illCrfDataupdated[9458].value
														.split('|')
														.indexOf('ToD - UnSubscribe ToD') >= 0 ? (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															checked
															readOnly
														/>
													) : (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															disabled
														/>
													)}
												</div>
											</div>
											<div className='over_hid pad_t2'>
												<div className='fleft width80 pad_t2'>
													{' '}
													ToD - Change of Port Speed (Bandwidth){' '}
												</div>
												<div className='fleft'>
													{illCrfDataupdated[9458].value
														.split('|')
														.indexOf(
															'ToD - Change of Port Speed (Bandwidth)'
														) >= 0 ? (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															checked
															readOnly
														/>
													) : (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															disabled
														/>
													)}
												</div>
											</div>
											<div className='signate float_img'>
												{firstCaps !== '' ? (
													<div style={{ padding: '10px' }} className={Signcss}>
														{firstCaps + '  .  '}
														<span className='under_line'>{secondBold}</span>
													</div>
												) : (
													''
												)}
												{imageData != null && imageData !== '' ? (
													<div
														style={{ padding: '10px' }}
														className='signate_img'
													>
														<img
															src={
																imageData != null
																	? 'data:' +
																	  imageType +
																	  ';base64,' +
																	  encode(imageData)
																	: ''
															}
															alt={'Company Seal'}
														/>
													</div>
												) : (
													''
												)}
											</div>
											<div className='over_hid pad_t2'>
												<div className='fleft width80 pad_t2'>
													BoD - Subscribe ToD{' '}
												</div>
												<div className='fleft'>
													{illCrfDataupdated[9458].value
														.split('|')
														.indexOf('BoD - Subscribe ToD') >= 0 ? (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															checked
															readOnly
														/>
													) : (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															disabled
														/>
													)}
												</div>
											</div>
											<div className='over_hid pad_t2'>
												<div className='fleft width80 pad_t2'>
													{' '}
													BoD - UnSubscribe ToD{' '}
												</div>
												<div className='fleft'>
													{illCrfDataupdated[9458].value
														.split('|')
														.indexOf('BoD - UnSubscribe ToD') >= 0 ? (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															checked
															readOnly
														/>
													) : (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															disabled
														/>
													)}
												</div>
											</div>
											<div className='over_hid pad_t2'>
												<div className='fleft width80 pad_t2'>
													BoD - Change of Port Speed (Bandwidth){' '}
												</div>
												<div className='fleft'>
													{illCrfDataupdated[9458].value
														.split('|')
														.indexOf(
															'BoD - Change of Port Speed (Bandwidth)'
														) >= 0 ? (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															checked
															readOnly
														/>
													) : (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															disabled
														/>
													)}
												</div>
											</div>
											<div className='over_hid pad_t2'>
												<div className='fleft width80 pad_t2'>
													Subscribe Nth Percentile{' '}
												</div>
												<div className='fleft'>
													{illCrfDataupdated[9458].value
														.split('|')
														.indexOf('Subscribe Nth Percentile') >= 0 ? (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															checked
															readOnly
														/>
													) : (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															disabled
														/>
													)}
												</div>
											</div>
											<div className='over_hid pad_t2'>
												<div className='fleft width80 pad_t2'>
													{' '}
													Un Subscribe Nth Percentile{' '}
												</div>
												<div className='fleft'>
													{illCrfDataupdated[9458].value
														.split('|')
														.indexOf('Un Subscribe Nth Percentile') >= 0 ? (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															checked
															readOnly
														/>
													) : (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															disabled
														/>
													)}
												</div>
											</div>
											<div className='over_hid pad_t2'>
												<div className='fleft width80 pad_t2'>
													{' '}
													Change of Nth Percentile Value{' '}
												</div>
												<div className='fleft'>
													{illCrfDataupdated[9458].value
														.split('|')
														.indexOf('Change of Nth Percentile Value') >= 0 ? (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															checked
															readOnly
														/>
													) : (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															disabled
														/>
													)}
												</div>
											</div>
											<div className='over_hid pad_t2'>
												<div className='fleft width80 pad_t2'>
													{' '}
													Nth Percentile- Change of Port Speed (Bandwidth){' '}
												</div>
												<div className='fleft'>
													{illCrfDataupdated[9458].value
														.split('|')
														.indexOf(
															'Nth Percentile- Change of Port Speed (Bandwidth)'
														) >= 0 ? (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															checked
															readOnly
														/>
													) : (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															disabled
														/>
													)}
												</div>
											</div>
											<div className='over_hid pad_t2'>
												<div className='fleft width80 pad_t2'>
													{' '}
													Subscribe DDoS{' '}
												</div>
												<div className='fleft'>
													{illCrfDataupdated[9458].value
														.split('|')
														.indexOf('Subscribe DDoS') >= 0 ? (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															checked
															readOnly
														/>
													) : (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															disabled
														/>
													)}
												</div>
											</div>
											<div className='over_hid pad_t2'>
												<div className='fleft width80 pad_t2'>
													Unsubscribe DDoS{' '}
												</div>
												<div className='fleft'>
													{illCrfDataupdated[9458].value
														.split('|')
														.indexOf('Unsubscribe DDoS') >= 0 ? (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															checked
															readOnly
														/>
													) : (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															disabled
														/>
													)}
												</div>
											</div>
											<div className='over_hid pad_t2'>
												<div className='fleft width80 pad_t2'>
													Change of DDoS bandwidth{' '}
												</div>
												<div className='fleft'>
													{illCrfDataupdated[9458].value
														.split('|')
														.indexOf('Change of DDoS bandwidth') >= 0 ? (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															checked
															readOnly
														/>
													) : (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															disabled
														/>
													)}
												</div>
											</div>
											<div className='over_hid pad_t2'>
												<div className='fleft width80 pad_t2'>
													Change of DDoS Flavour{' '}
												</div>
												<div className='fleft'>
													{illCrfDataupdated[9458].value
														.split('|')
														.indexOf('Change of DDoS Flavour') >= 0 ? (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															checked
															readOnly
														/>
													) : (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															disabled
														/>
													)}
												</div>
											</div>
											<div className='over_hid pad_t2'>
												<div className='fleft width80 pad_t2'>
													Subscribe Managed Services{' '}
												</div>
												<div className='fleft'>
													{illCrfDataupdated[9458].value
														.split('|')
														.indexOf('Subscribe Managed Services') >= 0 ? (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															checked
															readOnly
														/>
													) : (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															disabled
														/>
													)}
												</div>
											</div>

											<div className='over_hid pad_t2'>
												<div className='fleft width80 pad_t2'>
													{' '}
													Unsubscribe Managed Services{' '}
												</div>
												<div className='fleft'>
													{illCrfDataupdated[9458].value
														.split('|')
														.indexOf('Unsubscribe Managed Services') >= 0 ? (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															checked
															readOnly
														/>
													) : (
														<input
															className='form_col width37px'
															type='checkbox'
															name=''
															value=''
															disabled
														/>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</page>
					<page size='A4'>
						<div className='section2'>
							<div className='font8'>
								<div className='over_hid pad_t15'>
									<div className='fleft width72px'> &nbsp; </div>
									<div className='fleft width45 pad_l5per'>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												Change of Managed Services Variant{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Change of Managed Services Variant') >=
												0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												{' '}
												Subscribe Managed Security Services{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Subscribe Managed Security Services') >=
												0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												Unsubscribe Managed Security Services{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Unsubscribe Managed Security Services') >=
												0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												Change of Managed security Services Variant{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf(
														'Change of Managed security Services Variant'
													) >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												Subscribe SMTP Relay Services{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Subscribe SMTP Relay Services') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												Unsubscribe SMTP Relay Service{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Unsubscribe SMTP Relay Service') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												Subscribe Authoritative DNS Services{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Subscribe Authoritative DNS Services') >=
												0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												Unsubscribe Authoritative DNS Services{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Unsubscribe Authoritative DNS Services') >=
												0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												Change of A DNS Service parameters{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Change of A DNS Service parameters') >=
												0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												{' '}
												Subscribe Managed WiFi Service{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Subscribe Managed WiFi Service') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												Unsubscribe Managed WiFi Service{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Unsubscribe Managed WiFi Service') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												Subscribe Managed Proxy service{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Subscribe Managed Proxy service') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												{' '}
												Unsubscribe Managed Proxy service{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Unsubscribe Managed Proxy service') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												Change in SLA Type{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Change in SLA Type') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												{' '}
												Change in Self care service variant{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Change in Self care service variant') >=
												0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
										<div className='over_hid pad_t2'>
											<div className='fleft width80 pad_t2'>
												{' '}
												Revision in charges{' '}
											</div>
											<div className='fleft'>
												{illCrfDataupdated[9458].value
													.split('|')
													.indexOf('Revision in charges') >= 0 ? (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														checked
														readOnly
													/>
												) : (
													<input
														className='form_col width37px'
														type='checkbox'
														name=''
														value=''
														disabled
													/>
												)}
											</div>
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Company Name Change
								</p>
								<div className='check over_hid pad_t6'>
									<div className='fleft width170px pad_t3'>
										Existing Name of company
									</div>
									<div className='fleft width49'>
										<input
											className='form_col widthfull'
											type='text'
											name=''
											value={illCrfDataupdated[9460].value}
											readOnly
										/>
									</div>
								</div>
								<div className='check over_hid pad_t6'>
									<div className='fleft width170px pad_t3'>&nbsp;</div>
									<div className='fleft width49'>
										<input
											className='form_col widthfull'
											type='text'
											name=''
											value=''
										/>
									</div>
								</div>
								<div className='check over_hid pad_t6'>
									<div className='fleft width170px pad_t3'>
										New Name of company
									</div>
									<div className='fleft width49'>
										<input
											className='form_col widthfull'
											type='text'
											name=''
											value={illCrfDataupdated[9461].value}
											readOnly
										/>
									</div>
								</div>
								<div className='check over_hid pad_t6'>
									<div className='fleft width170px pad_t3'>&nbsp;</div>
									<div className='fleft width49'>
										<input
											className='form_col widthfull'
											type='text'
											name=''
											value=''
										/>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Customer Contact Details Change
								</p>
								<div className='signate float_img'>
									{firstCaps !== '' ? (
										<div style={{ padding: '10px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData !== null && imageData !== '' ? (
										<div style={{ padding: '10px' }} className='signate_img'>
											<img
												src={
													imageData != null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
								<div className='check over_hid pad_t6'>
									<div className='fleft width170px pad_t3'>
										Existing telephone Number
									</div>
									<div className='fleft width30'>
										<input
											className='form_col widthfull'
											type='text'
											name=''
											value={illCrfDataupdated[9463].value}
											readOnly
										/>
									</div>
								</div>
								<div className='check over_hid pad_t6'>
									<div className='fleft width170px pad_t3'>
										New telephone Number
									</div>
									<div className='fleft width30'>
										<input
											className='form_col widthfull'
											type='text'
											name=''
											value={illCrfDataupdated[9464].value}
											readOnly
										/>
									</div>
								</div>
								<div className='check over_hid pad_t6'>
									<div className='fleft width170px pad_t3'>
										Existing email address
									</div>
									<div className='fleft width30'>
										<input
											className='form_col widthfull'
											type='text'
											name=''
											value={illCrfDataupdated[9465].value}
											readOnly
										/>
									</div>
								</div>
								<div className='check over_hid pad_t6'>
									<div className='fleft width170px pad_t3'>
										New email address
									</div>
									<div className='fleft width30'>
										<input
											className='form_col widthfull'
											type='text'
											name=''
											value={illCrfDataupdated[9466].value}
											readOnly
										/>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Billing Address Change
								</p>
								<p className='font12 margin0'> New Billing Address</p>
								<div className='check over_hid pad_t6'>
									<div className='fleft wid120p pad_t3'>
										Contact Person name
									</div>
									<div className='fleft width81'>
										<input
											className='form_col widthfull'
											type='text'
											name=''
											value={illCrfDataupdated[9469].value}
											readOnly
										/>
									</div>
								</div>
								<div className='over_hid pad_t15'>
									<div className='fleft wid50'>
										<div className='check over_hid '>
											<div className='fleft wid120p pad_t3'>Designation</div>
											<div className='fleft width63'>
												<input
													className='form_col widthfull'
													type='text'
													name=''
													value={illCrfDataupdated[9470].value}
													readOnly
												/>
											</div>
										</div>
									</div>
									<div className='fleft wid50'>
										<div className='check over_hid '>
											<div className='fleft wid120p pad_t3 textcenter'>
												<span className=''>Dept. Name</span>
											</div>
											<div className='fleft width63'>
												<input
													className='form_col widthfull'
													type='text'
													name=''
													value={illCrfDataupdated[9471].value}
													readOnly
												/>
											</div>
										</div>
									</div>
								</div>
								<div className='check over_hid pad_t6'>
									<div className='fleft wid120p pad_t3'>Address</div>
									<div className='fleft width81'>
										<input
											className='form_col widthfull'
											type='text'
											name=''
											value={illCrfDataupdated[9472].value}
											readOnly
										/>
									</div>
								</div>
								<div className='check over_hid pad_t6'>
									<div className='fleft wid120p pad_t3'> &nbsp; </div>
									<div className='fleft width81'>
										<input
											className='form_col widthfull'
											type='text'
											name=''
											value=''
										/>
									</div>
								</div>
								<div className='over_hid pad_t15'>
									<div className='fleft wid50'>
										<div className='check over_hid '>
											<div className='fleft wid120p pad_t3'>
												City / Village / Post Office
											</div>
											<div className='fleft width63'>
												<input
													className='form_col widthfull'
													type='text'
													name=''
													value={illCrfDataupdated[9473].value}
													readOnly
												/>
											</div>
										</div>
									</div>
									<div className='fleft wid25'>
										<div className='check over_hid '>
											<div className='fleft pad_t3 textcenter pad_l10 pad_r5'>
												<span className=''>Pin</span>
											</div>
											<div className='fleft width63'>
												<input
													className='form_col widthfull'
													type='text'
													name=''
													value={illCrfDataupdated[9474].value}
													readOnly
												/>
											</div>
										</div>
									</div>
									<div className='fleft wid25'>
										<div className='check over_hid '>
											<div className='fleft pad_t3 textcenter pad_l10 pad_r5'>
												<span className=''>Telephone</span>
											</div>
											<div className='fleft width63'>
												<input
													className='form_col width90'
													type='text'
													name=''
													value={illCrfDataupdated[9475].value}
													readOnly
												/>
											</div>
										</div>
									</div>
								</div>
								<div className='auth margin0 over_hid '>
									<div className='fleft wid120p pad_t3 text_r'>
										Landmark &nbsp; &nbsp;{' '}
									</div>
									<div className='fleft width63'>
										<input
											className='form_col widthfull'
											type='text'
											name=''
											value={illCrfDataupdated[9476].value}
											readOnly
										/>
									</div>
								</div>
								<br />
								<br />
							</div>
						</div>
					</page>
					<page size='A4'>
						<div className='section2'>
							<div className='font8'>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Site Shifting
								</p>
								<p className='font12 margin0'> New Installation Address</p>
								<div className='check over_hid pad_t6'>
									<div className='fleft wid120p pad_t3'>
										Contact Person name
									</div>
									<div className='fleft width81'>
										<input
											className='form_col widthfull'
											type='text'
											name=''
											value={illCrfDataupdated[9479].value}
											readOnly
										/>
									</div>
								</div>
								<div className='over_hid pad_t15'>
									<div className='fleft wid50'>
										<div className='check over_hid '>
											<div className='fleft wid120p pad_t3'>Designation</div>
											<div className='fleft width63'>
												<input
													className='form_col widthfull'
													type='text'
													name=''
													value={illCrfDataupdated[9480].value}
													readOnly
												/>
											</div>
										</div>
									</div>
									<div className='fleft wid50'>
										<div className='check over_hid '>
											<div className='fleft wid120p pad_t3 textcenter'>
												<span className=''>Dept. Name</span>
											</div>
											<div className='fleft width63'>
												<input
													className='form_col widthfull'
													type='text'
													name=''
													value={illCrfDataupdated[9481].value}
													readOnly
												/>
											</div>
										</div>
									</div>
								</div>
								<div className='check over_hid pad_t6'>
									<div className='fleft wid120p pad_t3'>Address</div>
									<div className='fleft width81'>
										<input
											className='form_col widthfull'
											type='text'
											name=''
											value={illCrfDataupdated[9482].value}
											readOnly
										/>
									</div>
								</div>
								<div className='check over_hid pad_t6'>
									<div className='fleft wid120p pad_t3'> &nbsp; </div>
									<div className='fleft width81'>
										<input
											className='form_col widthfull'
											type='text'
											name=''
											value=''
										/>
									</div>
								</div>
								<div className='over_hid pad_t15'>
									<div className='fleft wid50'>
										<div className='check over_hid '>
											<div className='fleft wid120p pad_t3'>
												City / Village / Post Office
											</div>
											<div className='fleft width63'>
												<input
													className='form_col widthfull'
													type='text'
													name=''
													value={illCrfDataupdated[9483].value}
													readOnly
												/>
											</div>
										</div>
									</div>
									<div className='fleft wid25'>
										<div className='check over_hid '>
											<div className='fleft pad_t3 textcenter pad_l10 pad_r5'>
												<span className=''>Pin</span>
											</div>
											<div className='fleft width63'>
												<input
													className='form_col widthfull'
													type='text'
													name=''
													value={illCrfDataupdated[9484].value}
													readOnly
												/>
											</div>
										</div>
									</div>
									<div className='fleft wid25'>
										<div className='check over_hid '>
											<div className='fleft pad_t3 textcenter pad_l10 pad_r5'>
												<span className=''>Telephone</span>
											</div>
											<div className='fleft width63'>
												<input
													className='form_col width90'
													type='text'
													name=''
													value={illCrfDataupdated[9485].value}
													readOnly
												/>
											</div>
										</div>
									</div>
								</div>
								<div className='auth margin0 over_hid '>
									<div className='fleft wid120p pad_t3 text_r'>
										Landmark &nbsp; &nbsp;{' '}
									</div>
									<div className='fleft width63'>
										<input
											className='form_col widthfull'
											type='text'
											name=''
											value={illCrfDataupdated[9486].value}
											readOnly
										/>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									CIR Bandwidth Upgrade - Same Interface
								</p>
								<div className='pad_t10'>
									<div className='check over_hid'>
										<div className='fleft width130px pad_t3'>
											{' '}
											Existing Bandwidth{' '}
										</div>
										<div className='fleft width20'>
											<input
												className='form_col widthfull'
												type='text'
												name=''
												value={illCrfDataupdated[9488].value}
												readOnly
											/>
											Kbps / Mbps
										</div>
										<div className='fleft width130px pad_t3'>
											{' '}
											New Bandwidth{' '}
										</div>
										<div className='fleft width20'>
											<input
												className='form_col widthfull'
												type='text'
												name=''
												value={illCrfDataupdated[9489].value}
												readOnly
											/>
											Kbps / Mbps{' '}
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									CIR Bandwidth Downgrade - Same Interface
								</p>
								<div className='pad_t10'>
									<div className='check over_hid'>
										<div className='fleft width130px pad_t3'>
											{' '}
											Existing Bandwidth{' '}
										</div>
										<div className='fleft width20'>
											<input
												className='form_col widthfull'
												type='text'
												name=''
												value={illCrfDataupdated[9491].value}
												readOnly
											/>
											Kbps / Mbps
										</div>
										<div className='fleft width130px pad_t3'>
											{' '}
											New Bandwidth{' '}
										</div>
										<div className='fleft width20'>
											<input
												className='form_col widthfull'
												type='text'
												name=''
												value={illCrfDataupdated[9492].value}
												readOnly
											/>
											Kbps / Mbps{' '}
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									CIR Bandwidth Upgrade with Interface / LM / Media Change
								</p>
								<div className='pad_t10'>
									<div className='check over_hid'>
										<div className='fleft width130px pad_t3'>
											{' '}
											Existing Bandwidth{' '}
										</div>
										<div className='fleft width20'>
											<input
												className='form_col widthfull'
												type='text'
												name=''
												value={illCrfDataupdated[9494].value}
												readOnly
											/>
											Kbps / Mbps
										</div>
										<div className='fleft width130px pad_t3'>
											{' '}
											New Bandwidth{' '}
										</div>
										<div className='fleft width20'>
											<input
												className='form_col widthfull'
												type='text'
												name=''
												value={illCrfDataupdated[9495].value}
												readOnly
											/>
											Kbps / Mbps{' '}
										</div>
									</div>
								</div>
								<div className='pad_5t'>
									<div className='check over_hid'>
										<div className='fleft width130px pad_t3'>
											Existing Interface{' '}
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9496].value === 'V.35' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											V.35
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9496].value === 'G.703' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											G.703
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9496].value ===
											'Ethernet Electrical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Ethernet Electrical
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9496].value === 'BNC' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											BNC
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9496].value === 'Ethernet Optical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Ethernet Optical
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9496].value === 'Optical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Optical
										</div>
									</div>
								</div>
								<div className='pad_5t'>
									<div className='check over_hid'>
										<div className='fleft width130px pad_t3'>
											New Interface{' '}
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9497].value === 'V.35' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											V.35
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9497].value === 'G.703' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											G.703
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9497].value ===
											'Ethernet Electrical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Ethernet Electrical
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9497].value === 'BNC' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											BNC
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9497].value === 'Ethernet Optical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Ethernet Optical
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9497].value === 'Optical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Optical
										</div>
									</div>
								</div>
								<div className='pad_5t'>
									<div className='check gstcheck over_hid'>
										<div className='fleft pad_r25 pad_t3'>
											Interface Change with Change in Media{' '}
										</div>
										<div className='fleft pad_r25'>
											{' '}
											<span className='pad_r5'>Existing Media</span>
											{illCrfDataupdated[9499].value === 'Copper' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Copper
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9499].value === 'Fiber' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Fiber
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9499].value === 'UBR' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											UBR
										</div>
									</div>
								</div>
								<div className='pad_5t'>
									<div className='check gstcheck over_hid'>
										<div className='fleft pad_r25 w192px pad_t3'>&nbsp;</div>
										<div className='fleft pad_r25'>
											{' '}
											<span className='pad_r5'>New Media</span>
											{illCrfDataupdated[9500].value === 'Copper' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Copper
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9500].value === 'Fiber' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Fiber
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9500].value === 'UBR' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											UBR
										</div>
									</div>
								</div>
								<div className='pad_5t'>
									<div className='check gstcheck over_hid'>
										<div className='fleft pad_r25 pad_t3'>
											Interface Change with Change in Last Mile provider
										</div>
										<div className='fleft wid36'>
											{' '}
											<span className='pad_5t fleft pad_r5'>
												Existing LM Provider{' '}
											</span>
											<input
												className='form_col width56im'
												type='text'
												name=''
												value={illCrfDataupdated[9502].value}
												readOnly
											/>
										</div>
										<div className='fleft wid25'>
											{' '}
											<span className='pad_5t fleft pad_r5'>
												New LM Provider
											</span>
											<input
												className='form_col width47im'
												type='text'
												name=''
												value={illCrfDataupdated[9503].value}
												readOnly
											/>
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									CIR Bandwidth Downgrade with Interface / LM / Media Change
								</p>
								<div className='pad_t10'>
									<div className='check over_hid'>
										<div className='fleft width130px pad_t3'>
											{' '}
											Existing Bandwidth{' '}
										</div>
										<div className='fleft width20'>
											<input
												className='form_col widthfull'
												type='text'
												name=''
												value={illCrfDataupdated[9505].value}
												readOnly
											/>
											Kbps / Mbps
										</div>
										<div className='fleft width130px pad_t3'>
											{' '}
											New Bandwidth{' '}
										</div>
										<div className='fleft width20'>
											<input
												className='form_col widthfull'
												type='text'
												name=''
												value={illCrfDataupdated[9506].value}
												readOnly
											/>
											Kbps / Mbps{' '}
										</div>
									</div>
								</div>
								<div className='pad_5t'>
									<div className='check over_hid'>
										<div className='fleft width130px pad_t3'>
											Existing Interface{' '}
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9507].value === 'V.35' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											V.35
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9507].value === 'G.703' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											G.703
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9507].value ===
											'Ethernet Electrical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Ethernet Electrical
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9507].value === 'BNC' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											BNC
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9507].value === 'Ethernet Optical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Ethernet Optical
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9507].value === 'Optical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Optical
										</div>
									</div>
								</div>
								<div className='pad_5t'>
									<div className='check over_hid'>
										<div className='fleft width130px pad_t3'>
											New Interface{' '}
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9508].value === 'V.35' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											V.35
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9508].value === 'G.703' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											G.703
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9508].value ===
											'Ethernet Electrical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Ethernet Electrical
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9508].value === 'BNC' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											BNC
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9508].value === 'Ethernet Optical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Ethernet Optical
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9508].value === 'Optical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Optical
										</div>
									</div>
								</div>
								<div className='pad_5t'>
									<div className='check gstcheck over_hid'>
										<div className='fleft pad_r25 pad_t3'>
											Interface Change with Change in Media{' '}
										</div>
										<div className='signate float_img'>
											{firstCaps !== '' ? (
												<div style={{ padding: '10px' }} className={Signcss}>
													{firstCaps + '  .  '}
													<span className='under_line'>{secondBold}</span>
												</div>
											) : (
												''
											)}
											{imageData !== null && imageData !== '' ? (
												<div
													style={{ padding: '10px' }}
													className='signate_img'
												>
													<img
														src={
															imageData != null
																? 'data:' +
																  imageType +
																  ';base64,' +
																  encode(imageData)
																: ''
														}
														alt={'Company Seal'}
													/>
												</div>
											) : (
												''
											)}
										</div>
										<div className='fleft pad_r25'>
											{' '}
											<span className='pad_r5'>Existing Media</span>
											{illCrfDataupdated[9510].value === 'Copper' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Copper
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9510].value === 'Fiber' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Fiber
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9510].value === 'UBR' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											UBR
										</div>
									</div>
								</div>
								<div className='pad_5t'>
									<div className='check gstcheck over_hid'>
										<div className='fleft pad_r25 w192px pad_t3'>&nbsp;</div>
										<div className='fleft pad_r25'>
											{' '}
											<span className='pad_r5'>New Media</span>
											{illCrfDataupdated[9511].value === 'Copper' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Copper
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9511].value === 'Fiber' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Fiber
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9511].value === 'UBR' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											UBR
										</div>
									</div>
								</div>
								<div className='pad_5t'>
									<div className='check gstcheck over_hid'>
										<div className='fleft pad_r25 pad_t3'>
											Interface Change with Change in Last Mile provider
										</div>
										<div className='fleft wid36'>
											{' '}
											<span className='pad_5t fleft pad_r5'>
												Existing LM Provider{' '}
											</span>
											<input
												className='form_col width56im'
												type='text'
												name=''
												value={illCrfDataupdated[9513].value}
												readOnly
											/>
										</div>
										<div className='fleft wid25'>
											{' '}
											<span className='pad_5t fleft pad_r5'>
												New LM Provider
											</span>
											<input
												className='form_col width47im'
												type='text'
												name=''
												value={illCrfDataupdated[9514].value}
												readOnly
											/>
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Interface Change{' '}
								</p>
								<div className='pad_t10'>
									<div className='check over_hid'>
										<div className='fleft width130px pad_t3'>
											Existing Interface{' '}
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9516].value === 'V.35' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											V.35
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9516].value === 'G.703' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											G.703
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9516].value ===
											'Ethernet Electrical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Ethernet Electrical
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9516].value === 'BNC' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											BNC
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9516].value === 'Ethernet Optical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Ethernet Optical
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9516].value === 'Optical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Optical
										</div>
									</div>
								</div>
								<div className='pad_5t'>
									<div className='check over_hid'>
										<div className='fleft width130px pad_t3'>
											New Interface{' '}
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9517].value === 'V.35' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											V.35
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9517].value === 'G.703' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											G.703
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9517].value ===
											'Ethernet Electrical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Ethernet Electrical
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9517].value === 'BNC' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											BNC
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9517].value === 'Ethernet Optical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Ethernet Optical
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9517].value === 'Optical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Optical
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Interface Change with Media / LM Change
								</p>

								<div className='pad_t10'>
									<div className='check gstcheck over_hid'>
										<div className='fleft width130px pad_t3'>
											Existing Interface{' '}
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9519].value === 'V.35' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											V.35
										</div>
										<div className='fleft pad_r15'>
											{illCrfDataupdated[9519].value === 'G.703' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											G.703
										</div>
										<div className='fleft pad_r15'>
											{illCrfDataupdated[9519].value ===
											'Ethernet Electrical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Ethernet Electrical
										</div>
										<div className='fleft pad_r15'>
											{illCrfDataupdated[9519].value === 'BNC' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											BNC
										</div>
										<div className='fleft pad_r15'>
											{illCrfDataupdated[9519].value === 'Ethernet Optical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Ethernet Optical
										</div>
										<div className='fleft pad_r15'>
											{illCrfDataupdated[9519].value === 'Optical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Optical
										</div>
									</div>
								</div>
								<div className='pad_5t'>
									<div className='check gstcheck over_hid'>
										<div className='fleft width130px pad_t3'>
											New Interface{' '}
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9520].value === 'V.35' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											V.35
										</div>
										<div className='fleft pad_r15'>
											{illCrfDataupdated[9520].value === 'G.703' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											G.703
										</div>
										<div className='fleft pad_r15'>
											{illCrfDataupdated[9520].value ===
											'Ethernet Electrical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Ethernet Electrical
										</div>
										<div className='fleft pad_r15'>
											{illCrfDataupdated[9520].value === 'BNC' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											BNC
										</div>
										<div className='fleft pad_r15'>
											{illCrfDataupdated[9520].value === 'Ethernet Optical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Ethernet Optical
										</div>
										<div className='fleft pad_r15'>
											{illCrfDataupdated[9520].value === 'Optical' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Optical
										</div>
									</div>
								</div>
								<div className='pad_5t'>
									<div className='check gstcheck over_hid'>
										<div className='fleft pad_r25 pad_t3'>
											Interface Change with Change in Media{' '}
										</div>
										<div className='fleft pad_r25'>
											{' '}
											<span className='pad_r5'>Existing Media</span>
											{illCrfDataupdated[9522].value === 'Copper' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Copper
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9522].value === 'Fiber' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Fiber
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9522].value === 'UBR' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											UBR
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9522].value === 'Other' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Other
										</div>{' '}
									</div>
								</div>
								<div className='pad_5t'>
									<div className='check gstcheck over_hid'>
										<div className='fleft pad_r25 w192px pad_t3'>&nbsp;</div>
										<div className='fleft pad_r25'>
											{' '}
											<span className='pad_r5'>New Media</span>
											{illCrfDataupdated[9523].value === 'Copper' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Copper
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9523].value === 'Fiber' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Fiber
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9523].value === 'UBR' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											UBR
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9523].value === 'Other' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Other
										</div>
									</div>
								</div>
								<div className='pad_5t'>
									<div className='check gstcheck over_hid'>
										<div className='fleft pad_r25 pad_t3'>
											Interface Change with Change in Last Mile provider
										</div>
										<div className='fleft wid36'>
											{' '}
											<span className='pad_5t fleft pad_r5'>
												Existing LM Provider{' '}
											</span>
											<input
												className='form_col width56im'
												type='text'
												name=''
												value={illCrfDataupdated[9525].value}
												readOnly
											/>
										</div>
										<div className='fleft wid25'>
											{' '}
											<span className='pad_5t fleft pad_r5'>
												New LM Provider
											</span>
											<input
												className='form_col width47im'
												type='text'
												name=''
												value={illCrfDataupdated[9526].value}
												readOnly
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</page>
					<page size='A4'>
						<div className='section2'>
							<div className='font8'>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Routing Protocol change
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width20 pad_t3'>
											{' '}
											Existing Protocol{' '}
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9528].value === 'BGP' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											BGP
										</div>
										<div className='fleft'>
											{illCrfDataupdated[9528].value === 'Static' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											<span className='pad_5t flright pad_l'>
												{' '}
												&nbsp; Static
											</span>
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width20 pad_t3'>New Protocol </div>
										<div className='fleft width16'>
											{illCrfDataupdated[9529].value === 'BGP' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											BGP
										</div>
										<div className='fleft'>
											{illCrfDataupdated[9529].value === 'Static' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											<span className='pad_5t flright pad_l'>
												{' '}
												&nbsp; Static
											</span>
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width15 pad_t3 textcenter'>
											If BGP{' '}
										</div>
										<div className='fleft width10 pad_t3'> AS Number</div>
										<div className='fleft'>
											<input
												className='form_col width200px'
												type='text'
												name=''
												value={illCrfDataupdated[9530].value}
												readOnly
											/>
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width20 pad_t3'>&nbsp; </div>
										<div className='fleft width16'>
											{illCrfDataupdated[9531].value === 'Partial Routes' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Partial Routes
										</div>
										<div className='fleft'>
											{illCrfDataupdated[9531].value === 'Full Routes' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Full Routes{' '}
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width20 pad_t3'>&nbsp; </div>
										<div className='fleft width16 pad_t3'>
											{' '}
											Loop Back IP Address
										</div>
										<div className='fleft'>
											<input
												className='form_col width200px fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9532].value}
												readOnly
											/>
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Addition of CPE
								</p>
								<div className='domestictable'>
									<table
										style={{
											width: '70%',
											cellSpacing: '0',
											cellPadding: '0',
										}}
									>
										<tbody>
											<tr>
												<td>Sr. No.</td>
												<td>Type (Standard/Other)</td>
												<td>Make</td>
												<td>Model</td>
												<td>Version</td>
											</tr>
											<tr>
												<td>1</td>
												<td>{illCrfDataupdated[9534].value}</td>
												<td>{illCrfDataupdated[9535].value}</td>
												<td>{illCrfDataupdated[9536].value}</td>
												<td>{illCrfDataupdated[9537].value}</td>
											</tr>
											<tr>
												<td>2</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td>3</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td>4</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td>5</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
										</tbody>
									</table>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Deletion of CPE
								</p>
								<div className='domestictable'>
									<table
										style={{
											width: '70%',
											cellSpacing: '0',
											cellPadding: '0',
										}}
									>
										<tbody>
											<tr>
												<td>Sr. No.</td>
												<td>Type (Standard/Other)</td>
												<td>Make</td>
												<td>Model</td>
												<td>Version</td>
											</tr>
											<tr>
												<td>1</td>
												<td>{illCrfDataupdated[9539].value}</td>
												<td>{illCrfDataupdated[9540].value}</td>
												<td>{illCrfDataupdated[9541].value}</td>
												<td>{illCrfDataupdated[9542].value}</td>
											</tr>
											<tr>
												<td>2</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td>3</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td>4</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
											<tr>
												<td>5</td>
												<td></td>
												<td></td>
												<td></td>
												<td></td>
											</tr>
										</tbody>
									</table>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									{' '}
									Additional WAN IP Address
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width30 pad_t3'>
											Number of WAN IP addresses requested
										</div>
										<div className='fleft'>
											<input
												className='form_col width56im fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9544].value}
												readOnly
											/>
											{/* <input className="form_col width50px fleft" type="text" name="" value=""  />
                                          <input className="form_col width50px fleft" type="text" name="" value=""  /> */}
											<span className='in_block pad_5t pad_l10'>
												(In multiple of 8 IP Addresses){' '}
											</span>{' '}
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width35 pad_r15 pad_t3'>
											Mention if IP addresses are required from Specific Pool
										</div>
										<div className='fleft'>
											<input
												className='form_col width200px fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9545].value}
												readOnly
											/>
											{/* <input className="form_col width50px fleft" type="text" name="" value="" />
                                          <input className="form_col width30px fleft" type="text" name="" value=""  />
                                          <input className="form_col width30px fleft" type="text" name="" value=""  />
                                          <span className="fleft width50px pad_5t textcenter"> / </span>
                                          <input className="form_col width30px fleft" type="text" name="" value=""  /> */}
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Addition of LAN IP Address
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width20 pad_t3'>IP Address range</div>
										<div className='fleft'>
											<input
												className='form_col width200px  fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9547].value}
												readOnly
											/>
											{/* <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                          <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                          <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                          <span className="fleft width50px pad_5t textcenter"> / </span>
                                          <input className="form_col width30px fleft" type="text" name="" type="text" name="" value=""  /> */}
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width20 pad_t3'>&nbsp;</div>
										<div className='fleft'>
											<input
												className='form_col width200px fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9548].value}
												readOnly
											/>
											{/* <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                          <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                          <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                          <span className="fleft width50px pad_5t textcenter"> / </span>
                                          <input className="form_col width30px fleft" type="text" name="" type="text" name="" value=""  /> */}
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width20 pad_t3'>&nbsp;</div>
										<div className='fleft'>
											<input
												className='form_col width200px fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9549].value}
												readOnly
											/>
											{/* <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                          <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                          <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                          <span className="fleft width50px pad_5t textcenter"> / </span>
                                          <input className="form_col width30px fleft" type="text" name="" type="text" name="" value=""  /> */}
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Deletion of LAN IP Address
								</p>
								<div className='signate float_img'>
									{firstCaps !== '' ? (
										<div style={{ padding: '10px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData != null && imageData !== '' ? (
										<div style={{ padding: '10px' }} className='signate_img'>
											<img
												src={
													imageData != null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width20 pad_t3'>IP Address range</div>
										<div className='fleft'>
											<input
												className='form_col width200px fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9551].value}
												readOnly
											/>
											{/* <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                          <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                          <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                          <span className="fleft width50px pad_5t textcenter"> / </span>
                                          <input className="form_col width30px fleft" type="text" name="" type="text" name="" value=""  /> */}
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width20 pad_t3'>&nbsp;</div>
										<div className='fleft'>
											<input
												className='form_col width200px fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9552].value}
												readOnly
											/>
											{/* <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                          <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                          <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                          <span className="fleft width50px pad_5t textcenter"> / </span>
                                          <input className="form_col width30px fleft" type="text" name="" type="text" name="" value=""  /> */}
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width20 pad_t3'>&nbsp;</div>
										<div className='fleft'>
											<input
												className='form_col width200px fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9553].value}
												readOnly
											/>
											{/* <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                          <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                          <input className="form_col width50px fleft" type="text" name="" type="text" name="" value=""  />
                                          <span className="fleft width50px pad_5t textcenter"> / </span>
                                          <input className="form_col width30px fleft" type="text" name="" type="text" name="" value=""  /> */}
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									VOIP Enable{' '}
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width20 pad_t3'>
											{' '}
											Existing status of VOIP Port{' '}
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9555].value === 'Disable' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Disable
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width20 pad_t3'>
											{' '}
											Desired Status of VOIP Port{' '}
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9556].value === 'Enable' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Enable
										</div>
									</div>
								</div>
							</div>
						</div>
					</page>
					<page size='A4'>
						<div className='section2'>
							<div className='font8'>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									VOIP Disable{' '}
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width20 pad_t3'>
											{' '}
											Existing status of VOIP Port{' '}
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9558].value === 'Disable' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Disable
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width20 pad_t3'>
											{' '}
											Desired Status of VOIP Port{' '}
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9559].value === 'Enable' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Enable
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Billing Periodicity Change
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width20'>
											{' '}
											Existing Billing Periodicity{' '}
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9561].value === 'Monthly' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Monthly
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9561].value === 'Bi-Monthly' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Bi-Monthly
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9561].value === 'Quarterly' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Quarterly
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9561].value === 'Half Yearly' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Half Yearly
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9561].value === 'Annualy' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Annualy
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width20'>
											Revised Billing Periodicity{' '}
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9562].value === 'Monthly' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Monthly
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9562].value === 'Bi-Monthly' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Bi-Monthly
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9562].value === 'Quarterly' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Quarterly
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9562].value === 'Half Yearly' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Half Yearly
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9562].value === 'Annualy' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Annualy
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Change in Service Variant
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width20'>
											Existing Service Variant{' '}
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9564].value ===
											'Business ILL (1:1)' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Business ILL (1:1)
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9564].value ===
											'Business Internet Port' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Business Internet Port{' '}
										</div>
										<div className='fleft width16 pad_l10'>
											{illCrfDataupdated[9564].value ===
											'Value Plus ILL (1:4)' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Value Plus ILL (1:4)
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9564].value ===
											'Priority ILL (1:1)' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Priority ILL (1:1)
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width20'>
											New Service Variant{' '}
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9565].value ===
											'Business ILL (1:1)' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Business ILL (1:1)
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9565].value ===
											'Business Internet Port' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Business Internet Port{' '}
										</div>
										<div className='fleft width16 pad_l10'>
											{illCrfDataupdated[9565].value ===
											'Value Plus ILL (1:4)' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Value Plus ILL (1:4)
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9565].value ===
											'Priority ILL (1:1)' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Priority ILL (1:1)
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									ToD - Subscribe ToD
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width25'>
											ToD Service Required{' '}
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9567].value === 'Yes' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Yes
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width10'>&nbsp;</div>
										<div className='fleft width16'>If Yes</div>
									</div>
								</div>
								<div className=''>
									<div className='check over_hid'>
										<div className='fleft pad_t3 width10'>&nbsp;</div>
										<div className='fleft width25 pd_t5'>
											Maximum Port Speed (Bandwidth)
										</div>
										<div className='signate float_img'>
											{firstCaps !== '' ? (
												<div style={{ padding: '10px' }} className={Signcss}>
													{firstCaps + '  .  '}
													<span className='under_line'>{secondBold}</span>
												</div>
											) : (
												''
											)}
											{imageData != null && imageData !== '' ? (
												<div
													style={{ padding: '10px' }}
													className='signate_img'
												>
													<img
														src={
															imageData != null
																? 'data:' +
																  imageType +
																  ';base64,' +
																  encode(imageData)
																: ''
														}
														alt={'Company Seal'}
													/>
												</div>
											) : (
												''
											)}
										</div>
										<div className='fleft wid36'>
											<input
												className='form_col width56im fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9568].value}
												readOnly
											/>
											<span className='pad_5t fleft pad_l10'>
												(Kbps / Mbps)
											</span>{' '}
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid'>
										<div className='fleft pad_t3 width30'>&nbsp;</div>
										<div className='fleft width50 pd_t5'>
											(Maximum port speed shall be eight times CIR bandwidth))
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									ToD - UnSubscribe ToD
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width20'>
											{' '}
											ToD Service Required{' '}
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9571].value === 'No' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											No
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									ToD - Change in port speed (bandwidth)
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width30 pad_t3'>
											Existing Maximum Port Speed (Bandwidth)
										</div>
										<div className='fleft'>
											<input
												className='form_col width200px fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9573].value}
												readOnly
											/>
											<span className='in_block pad_5t pad_l10'>
												(Kbps / Mbps){' '}
											</span>{' '}
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width30 pad_t3'>
											New Maximum Port Speed (Bandwidth)
										</div>
										<div className='fleft'>
											<input
												className='form_col width200px fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9574].value}
												readOnly
											/>
											<span className='in_block pad_5t pad_l10'>
												(Kbps / Mbps){' '}
											</span>{' '}
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width20 pad_t3'>&nbsp;</div>
										<div className='fleft'>
											{' '}
											(Maximum port speed shall be eight times CIR bandwidth){' '}
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									BoD - Subscribe BoD
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width25'>
											BoD Service Required{' '}
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9577].value === 'Yes' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Yes
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width10'>&nbsp;</div>
										<div className='fleft width16'>If Yes</div>
									</div>
								</div>
								<div className=''>
									<div className='check over_hid'>
										<div className='fleft pad_t3 width10'>&nbsp;</div>
										<div className='fleft width25 pd_t5'>
											Maximum Port Speed (Bandwidth)
										</div>
										<div className='fleft wid36'>
											<input
												className='form_col width56im fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9578].value}
												readOnly
											/>
											<span className='pad_5t fleft pad_l10'>
												(Kbps / Mbps)
											</span>{' '}
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid'>
										<div className='fleft pad_t3 width30'>&nbsp;</div>
										<div className='fleft width50 pd_t5'>
											(Maximum port speed shall be eight times CIR bandwidth))
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									BoD - Un Subscribe BoD
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width20'>
											{' '}
											BoD Service Required{' '}
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9581].value === 'No' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											No
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									BoD - Change in port speed (bandwidth)
								</p>

								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width30 pad_t3'>
											Existing Maximum Port Speed (Bandwidth)
										</div>
										<div className='fleft'>
											<input
												className='form_col width200px fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9583].value}
												readOnly
											/>
											<span className='in_block pad_5t pad_l10'>
												(Kbps / Mbps){' '}
											</span>{' '}
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width30 pad_t3'>
											New Maximum Port Speed (Bandwidth)
										</div>
										<div className='fleft'>
											<input
												className='form_col width200px fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9584].value}
												readOnly
											/>
											<span className='in_block pad_5t pad_l10'>
												(Kbps / Mbps){' '}
											</span>{' '}
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width20 pad_t3'>&nbsp;</div>
										<div className='fleft'>
											{' '}
											(Maximum port speed shall be eight times CIR bandwidth){' '}
										</div>
									</div>
								</div>
							</div>
						</div>
					</page>
					<page size='A4'>
						<div className='section2'>
							<div className='font8'>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Subscribe Nth percentile
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width25'>
											Nth percentile Service Required{' '}
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9587].value === 'Yes' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Yes
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width10'>&nbsp;</div>
										<div className='fleft width16'>If Yes</div>
									</div>
								</div>
								<div className=''>
									<div className='check over_hid '>
										<div className='fleft pad_t3 width10'>&nbsp;</div>
										<div className='fleft width10 pd_t5'>Value of N</div>
										<div className='fleft width16'>
											<input
												className='form_col width150px fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9588].value}
												readOnly
											/>
											(1 to 99)
										</div>
										<div className='fleft width16 pad_5t'>
											Port Speed (Bandwidth)
										</div>
										<div className='fleft'>
											<input
												className='form_col width150px fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9589].value}
												readOnly
											/>
											<span className='in_block pad_5t pad_l10'>
												(Kbps / Mbps){' '}
											</span>{' '}
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Un Subscribe Nth percentile
								</p>
								<div className='signate float_img'>
									{firstCaps !== '' ? (
										<div style={{ padding: '10px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData != null && imageData !== '' ? (
										<div style={{ padding: '10px' }} className='signate_img'>
											<img
												src={
													imageData != null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width25'>
											Nth percentile Service Required
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9591].value === 'No' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											No
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Change of Nth percentile Value
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width25'>
											Existing Value of N
										</div>
										<div className='fleft width16'>
											<input
												className='form_col width150px fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9593].value}
												readOnly
											/>
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width25'>New Value of N</div>
										<div className='fleft width16'>
											<input
												className='form_col width150px fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9594].value}
												readOnly
											/>
											(1 to 99)
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Nth percentile - Change of port speed (bandwidth)
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width30 pad_t3'>
											Existing Port Speed (Bandwidth)
										</div>
										<div className='fleft'>
											<input
												className='form_col width200px fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9596].value}
												readOnly
											/>
											<span className='in_block pad_5t pad_l10'>
												(Kbps / Mbps){' '}
											</span>{' '}
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width30 pad_t3'>
											New Port Speed (Bandwidth)
										</div>
										<div className='fleft'>
											<input
												className='form_col width200px fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9597].value}
												readOnly
											/>
											<span className='in_block pad_5t pad_l10'>
												(Kbps / Mbps){' '}
											</span>{' '}
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Subscribe DDoS Service
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width25'>
											DDoS Service Required
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9599].value === 'Yes' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Yes
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width10'>&nbsp;</div>
										<div className='fleft width16'>If Yes</div>
									</div>
								</div>
								<div className='check over_hid '>
									<div className='fleft pad_t3 width10'>&nbsp;</div>
									<div className='fleft width25 pd_t5'>
										DDoS bandwidth required
									</div>
									<div className='fleft width50'>
										<input
											className='form_col width150px fleft'
											type='text'
											name=''
											value={illCrfDataupdated[9600].value}
											readOnly
										/>
										(Values shall range from 1X, 2X to 10X where X is ILL port
										bandwidth)
									</div>
								</div>
								<div className='check over_hid pad_t5'>
									<div className='fleft pad_t3 width10'>&nbsp;</div>
									<div className='fleft width15 pd_t5'>DDoS Flavour</div>
									<div className='fleft width10'>
										{illCrfDataupdated[9601].value === 'Gold' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Gold
									</div>
									<div className='fleft width20'>
										{illCrfDataupdated[9601].value === 'Silver' ? (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												checked
												readOnly
											/>
										) : (
											<input
												className='form_col'
												type='checkbox'
												name=''
												value=''
												disabled
											/>
										)}
										Silver
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									{' '}
									Un Subscribe DDoS Service
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width25'>
											DDoS Service Required
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9603].value === 'No' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											No
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									{' '}
									Change of DDoS Bandwidth
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width25'>
											Existing DDoS bandwidth
										</div>
										<div className='fleft'>
											<input
												className='form_col width150px fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9605].value}
												readOnly
											/>
											(Values shall range from 1X, 2X to 10X where X is ILL port
											bandwidth)
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width25'>
											New DDoS bandwidth required
										</div>
										<div className='fleft'>
											<input
												className='form_col width150px fleft'
												type='text'
												name=''
												value={illCrfDataupdated[9606].value}
												readOnly
											/>
											(Values shall range from 1X, 2X to 10X where X is ILL port
											bandwidth)
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Change of DDoS Flavour
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width22'>
											Existing DDoS Flavour
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9608].value === 'Gold' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Gold
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9608].value === 'Silver' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Silver
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width22'>
											Managed Service Variants
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9609].value === 'Gold' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Gold
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9609].value === 'Silver' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Silver
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Subscribe Managed Services
								</p>

								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width22'>
											Managed services required
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9611].value === 'Yes' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Yes
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width22'>
											{' '}
											Managed Service Variants{' '}
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9612].value === 'Standard' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Standard
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9612].value === 'Silver' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Silver
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9612].value === 'Silver Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Silver Plus
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9612].value === 'Gold' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Gold
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9612].value === 'Gold Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Gold Plus
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9612].value === 'Platinum' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Platinum
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9612].value === 'Platinum Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Platinum Plus
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Un Subscribe Managed Services
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width22'>
											Managed services required
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9614].value === 'No' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											No
										</div>
									</div>
								</div>
							</div>
						</div>
					</page>
					<page size='A4'>
						<div className='section2'>
							<div className='font8'>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Change of Managed Services Variant
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width22'>
											{' '}
											Existing Managed Service Variants
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9616].value === 'Standard' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Standard
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9616].value === 'Silver' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Silver
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9616].value === 'Silver Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Silver Plus
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9616].value === 'Gold' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Gold
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9616].value === ' Gold Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Gold Plus
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9616].value === 'Platinum' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Platinum
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9616].value === 'Platinum Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Platinum Plus
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width22'>
											New Managed Service Variants
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9617].value === 'Standard' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Standard
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9617].value === 'Silver' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Silver
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9617].value === 'Silver Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Silver Plus
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9617].value === 'Gold' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Gold
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9617].value === ' Gold Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Gold Plus
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9617].value === 'Platinum' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Platinum
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9617].value === 'Platinum Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Platinum Plus
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Subscribe Managed Security Services
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width24'>
											Managed security services required
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9619].value === 'Yes' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Yes
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width24'>
											Managed Security Variants{' '}
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9620].value === 'Standard' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Standard
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9620].value === 'Silver' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Silver
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9620].value === 'Silver Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Silver Plus
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9620].value === 'Gold' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Gold
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9620].value === 'Gold Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Gold Plus
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9620].value === 'Platinum' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Platinum
										</div>
										<div className='fleft'>
											{illCrfDataupdated[9620].value === 'Platinum Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Platinum Plus
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Un Subscribe Managed Security Services
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width24'>
											Managed security services required
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9622].value === 'No' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											No
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Change of Managed security Services Variant
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width24'>
											Existing Managed Security Variant{' '}
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9624].value === 'Standard' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Standard
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9624].value === 'Silver' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Silver
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9624].value === 'Silver Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Silver Plus
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9624].value === 'Gold' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Gold
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9624].value === 'Gold Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Gold Plus
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9624].value === 'Platinum' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Platinum
										</div>
										<div className='fleft'>
											{illCrfDataupdated[9624].value === 'Platinum Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Platinum Plus
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width24'>
											New Managed Security Variant{' '}
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9625].value === 'Standard' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Standard
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9625].value === 'Silver' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Silver
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9625].value === 'Silver Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Silver Plus
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9625].value === 'Gold' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Gold
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9625].value === 'Gold Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Gold Plus
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9625].value === 'Platinum' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Platinum
										</div>
										<div className='fleft'>
											{illCrfDataupdated[9625].value === 'Platinum Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Platinum Plus
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Subscribe SMTP Relay Service
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width24'>
											SMTP Relay service required
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9627].value === 'Yes' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Yes
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Un Subscribe SMTP Relay Service
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width24'>
											SMTP Relay service required
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9629].value === 'No' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											No
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Subscribe Authoritative DNS Service
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width24'>
											Authoritative DNS service required
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9635].value === 'No' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											No
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width10'>&nbsp;</div>
										<div className='fleft pad_r15'>If Yes</div>
										<div className='fleft width30'>
											<div className='domestictable'>
												<table
													style={{
														width: '100%',
														cellSpacing: '0',
														cellPadding: '0',
													}}
												>
													<tbody>
														<tr>
															<td>Domain Name</td>
															<td>Record Type</td>
														</tr>
														<tr>
															<td>{illCrfDataupdated[9632].value}</td>
															<td>{illCrfDataupdated[9633].value}</td>
														</tr>
														<tr>
															<td>&nbsp;</td>
															<td>&nbsp;</td>
														</tr>
														<tr>
															<td>&nbsp;</td>
															<td>&nbsp;</td>
														</tr>
														<tr>
															<td>&nbsp;</td>
															<td>&nbsp;</td>
														</tr>
														<tr>
															<td>&nbsp;</td>
															<td>&nbsp;</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Un Subscribe Authoritative DNS Service
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width24'>
											Authoritative DNS service required
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9635].value === 'No' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											No
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Change of Authoritative DNS Service parameters
								</p>
								<div className='signate float_img'>
									{firstCaps !== '' ? (
										<div style={{ padding: '10px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData != null && imageData !== '' ? (
										<div style={{ padding: '10px' }} className='signate_img'>
											<img
												src={
													imageData != null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width10'>&nbsp;</div>
										<div className='fleft pad_r15'>Existing</div>
										<div className='fleft width30'>
											<div className='domestictable'>
												<table
													style={{
														width: '100%',
														cellSpacing: '0',
														cellPadding: '0',
													}}
												>
													<tbody>
														<tr>
															<td>Domain Name</td>
															<td>Record Type</td>
														</tr>
														<tr>
															<td>{illCrfDataupdated[9637].value}</td>
															<td>{illCrfDataupdated[9638].value}</td>
														</tr>
														<tr>
															<td>&nbsp;</td>
															<td>&nbsp;</td>
														</tr>
														<tr>
															<td>&nbsp;</td>
															<td>&nbsp;</td>
														</tr>
														<tr>
															<td>&nbsp;</td>
															<td>&nbsp;</td>
														</tr>
														<tr>
															<td>&nbsp;</td>
															<td>&nbsp;</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
										<div className='fleft pad_r15 width10 text_r'>New</div>
										<div className='fleft width30'>
											<div className='domestictable'>
												<table
													style={{
														width: '100%',
														cellSpacing: '0',
														cellPadding: '0',
													}}
												>
													<tbody>
														<tr>
															<td>Domain Name</td>
															<td>Record Type</td>
														</tr>
														<tr>
															<td>{illCrfDataupdated[9639].value}</td>
															<td>{illCrfDataupdated[9640].value}</td>
														</tr>
														<tr>
															<td>&nbsp;</td>
															<td>&nbsp;</td>
														</tr>
														<tr>
															<td>&nbsp;</td>
															<td>&nbsp;</td>
														</tr>
														<tr>
															<td>&nbsp;</td>
															<td>&nbsp;</td>
														</tr>
														<tr>
															<td>&nbsp;</td>
															<td>&nbsp;</td>
														</tr>
													</tbody>
												</table>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</page>
					<page size='A4'>
						<div className='section2'>
							<div className='font8'>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Subscribe Managed WiFi Service
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width24'>
											Subscribe Managed WiFi service{' '}
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9642].value === 'Yes' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Yes
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Un Subscribe Managed WiFi Service
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width24'>
											Un Subscribe Managed WiFi Service{' '}
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9644].value === 'No' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											No
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Subscribe Managed Proxy Service
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width24'>
											Subscribe Managed Proxy Service{' '}
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9646].value === 'Yes' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Yes
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Un Subscribe Managed Proxy Service
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width24'>
											Un Subscribe Managed Proxy Service
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9648].value === 'No' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											No
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Change in SLA Type
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width15 pad_t3'>
											{' '}
											Existing SLA Type{' '}
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9650].value === 'Standard' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Standard
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9650].value === 'Customized' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Customized
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width15 pad_t3'> New SLA Type </div>
										<div className='fleft width16'>
											{illCrfDataupdated[9651].value === 'Standard' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Standard
										</div>
										<div className='fleft width16'>
											{illCrfDataupdated[9651].value === 'Customized' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Customized
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft width20 pad_7t textcenter  pad_r5'>
											If SLA Type Customized{' '}
										</div>
										<div className='fleft width16 pad_r20'>
											Service Availability (%)
											<input
												className='form_col widthfull'
												type='text'
												name=''
												value={illCrfDataupdated[9653].value}
												readOnly
											/>
										</div>
										<div className='fleft width12 pad_r20'>
											MTTR (Hours)
											<input
												className='form_col widthfull'
												type='text'
												name=''
												value={illCrfDataupdated[9654].value}
												readOnly
											/>
										</div>
										<div className='fleft width12 pad_r20'>
											Latency (ms)
											<input
												className='form_col widthfull'
												type='text'
												name=''
												value={illCrfDataupdated[9655].value}
												readOnly
											/>
										</div>
										<div className='fleft width12 pad_r20'>
											Packet Loss (%)
											<input
												className='form_col widthfull'
												type='text'
												name=''
												value={illCrfDataupdated[9656].value}
												readOnly
											/>
										</div>
										<div className='fleft width12'>
											Jitter (ms)
											<input
												className='form_col widthfull'
												type='text'
												name=''
												value={illCrfDataupdated[9657].value}
												readOnly
											/>
										</div>
									</div>
								</div>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Change in Self care service variant
								</p>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width24'>
											Existing Self Care Service Variant{' '}
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9659].value === 'None' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											None
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9659].value === 'Silver' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Silver
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9659].value === 'Silver Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Silver Plus
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9659].value === 'Gold' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Gold
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9659].value === 'Gold Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Gold Plus
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9659].value === 'Platinum' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Platinum
										</div>
										<div className='fleft'>
											{illCrfDataupdated[9659].value === 'Platinum Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Platinum Plus
										</div>
									</div>
								</div>
								<div className='pad_t5'>
									<div className='check over_hid pd_t5'>
										<div className='fleft pad_t3 width24'>
											New Self Care Service Variant
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9660].value === 'None' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											None
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9660].value === 'Silver' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Silver
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9660].value === 'Silver Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Silver Plus
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9660].value === 'Gold' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Gold
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9660].value === 'Gold Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Gold Plus
										</div>
										<div className='fleft pad_r25'>
											{illCrfDataupdated[9660].value === 'Platinum' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Platinum
										</div>
										<div className='fleft'>
											{illCrfDataupdated[9660].value === 'Platinum Plus' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											Platinum Plus
										</div>
									</div>
								</div>
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<div className='signate float_img'>
									{firstCaps !== '' ? (
										<div style={{ padding: '10px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData != null && imageData !== '' ? (
										<div style={{ padding: '10px' }} className='signate_img'>
											<img
												src={
													imageData != null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
							</div>
						</div>
					</page>
					<page size='A4'>
						<div className='section2'>
							<div className='font8'>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									Revision in Charges
								</p>
								<p>
									(Only do required changes. Leave others blank if no change)
								</p>
								<div className='char'>
									<div className='check over_hid pad_t5'>
										<div className='fleft width170px pad_t3'>
											{' '}
											Specify Currency{' '}
										</div>
										<div className='fleft width7'>
											{illCrfDataupdated[9663].value === 'INR' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											INR{' '}
										</div>
										<div className='fleft width7'>
											{illCrfDataupdated[9663].value === 'US$' ? (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													checked
													readOnly
												/>
											) : (
												<input
													className='form_col'
													type='checkbox'
													name=''
													value=''
													disabled
												/>
											)}
											US${' '}
										</div>
										<div className='fleft width15 textcenter'>
											{' '}
											One Time(A){' '}
										</div>
										<div className='fleft width15 textcenter'>
											{' '}
											Recurring (B){' '}
										</div>
										<div className='fleft width15 textcenter'>
											{' '}
											Security Deposit ©{' '}
										</div>
										<div className='fleft width15 textcenter'>
											{' '}
											Grand Total (A+B+C){' '}
										</div>
									</div>
									<div className='check over_hid'>
										<div className='fleft width170px pad_t3'>
											{' '}
											Service Rental
										</div>
										<div className='fleft width7'>&nbsp; </div>
										<div className='fleft width7'> &nbsp;</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9664].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9665].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9666].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9667].value}
												readOnly
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'>
											{' '}
											IP Address Charges
										</div>
										<div className='fleft width7'>&nbsp; </div>
										<div className='fleft width7'> &nbsp;</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9668].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9669].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'> &nbsp; </div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9670].value}
												readOnly
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'> SLA Charges </div>
										<div className='fleft width7'>&nbsp; </div>
										<div className='fleft width7'> &nbsp;</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9671].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9672].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'> &nbsp; </div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9673].value}
												readOnly
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'>
											Self Care Portal Service Charges
										</div>
										<div className='fleft width7'>&nbsp; </div>
										<div className='fleft width7'> &nbsp;</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9674].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9675].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'> &nbsp; </div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9676].value}
												readOnly
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'>BoD Charges</div>
										<div className='fleft width7'>&nbsp; </div>
										<div className='fleft width7'> &nbsp;</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9677].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9678].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 fnt6'>
											<span className='fleft pad_5t'>Rate per Mbps / Day</span>
											<input
												className='form_col wid35px flright'
												type='text'
												name=''
												value={illCrfDataupdated[9679].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9680].value}
												readOnly
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'>
											ToD Subscription Charges
										</div>
										<div className='fleft width7'>&nbsp; </div>
										<div className='fleft width7'> &nbsp;</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9681].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9682].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 fnt6'>
											<span className='fleft pad_5t'>Rate per Mbps / Hour</span>
											<input
												className='form_col wid35px flright'
												type='text'
												name=''
												value={illCrfDataupdated[9683].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9684].value}
												readOnly
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'>
											Nth Percentile Subscription Charges
										</div>
										<div className='fleft width7'>&nbsp; </div>
										<div className='fleft width7'> &nbsp;</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9685].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9686].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 fnt6'>
											<span className='fleft pad_5t'>
												Rate per Mbps / Month
											</span>
											<input
												className='form_col wid35px flright'
												type='text'
												name=''
												value={illCrfDataupdated[9687].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9688].value}
												readOnly
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'>
											DDoS Service Charges
										</div>
										<div className='fleft width7'>&nbsp; </div>
										<div className='fleft width7'> &nbsp;</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9689].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9690].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'> &nbsp; </div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9691].value}
												readOnly
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'>
											SMTP Relay Service Charges
										</div>
										<div className='fleft width7'>&nbsp; </div>
										<div className='fleft width7'> &nbsp;</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9692].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9693].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'> &nbsp; </div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9694].value}
												readOnly
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'>
											{' '}
											Managed Services Charges
										</div>
										<div className='fleft width7'>&nbsp; </div>
										<div className='fleft width7'> &nbsp;</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9695].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9696].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'> &nbsp; </div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9697].value}
												readOnly
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'>
											{' '}
											Managed CPE Charges{' '}
										</div>
										<div className='fleft width7'>&nbsp; </div>
										<div className='fleft width7'> &nbsp;</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9698].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9699].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'> &nbsp; </div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9700].value}
												readOnly
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'>
											{' '}
											Managed Security Charges{' '}
										</div>
										<div className='fleft width7'>&nbsp; </div>
										<div className='fleft width7'> &nbsp;</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9700].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9701].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'> &nbsp; </div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9702].value}
												readOnly
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'> CPE Rentals </div>
										<div className='fleft width7'>&nbsp; </div>
										<div className='fleft width7'> &nbsp;</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9704].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9705].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9706].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9707].value}
												readOnly
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'> </div>
										<div className='fleft pad_t5 pad_r5'>CPE 1 </div>
										<div className='fleft cpelist'>
											<input
												className='form_col wid90per'
												type='text'
												name=''
												value=''
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9708].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9709].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9710].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9711].value}
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'> </div>
										<div className='fleft pad_t5 pad_r5'>CPE 2 </div>
										<div className='fleft cpelist'>
											<input
												className='form_col wid90per'
												type='text'
												name=''
												value=''
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9712].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9713].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9714].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9715].value}
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'> </div>
										<div className='fleft pad_t5 pad_r5'>CPE 3</div>
										<div className='fleft cpelist'>
											<input
												className='form_col wid90per'
												type='text'
												name=''
												value=''
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9716].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9717].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9718].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9719].value}
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'> </div>
										<div className='fleft pad_t5 pad_r5'>CPE 4 </div>
										<div className='fleft cpelist'>
											<input
												className='form_col wid90per'
												type='text'
												name=''
												value=''
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9720].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9721].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9722].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9723].value}
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'> </div>
										<div className='fleft pad_t5 pad_r5'>CPE 5 </div>
										<div className='fleft cpelist'>
											<input
												className='form_col wid90per'
												type='text'
												name=''
												value=''
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9724].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9725].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9726].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9727].value}
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'>
											Miscellaneous Charges - 1{' '}
										</div>
										<div className='fleft width7 pad_t5'> &nbsp; </div>
										<div className='fleft width7'> &nbsp;</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9728].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9729].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'> &nbsp; </div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9730].value}
												readOnly
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'>
											Miscellaneous Charges - 2{' '}
										</div>
										<div className='fleft width7 pad_t5'> &nbsp; </div>
										<div className='fleft width7'> &nbsp;</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9731].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9732].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'> &nbsp; </div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9733].value}
												readOnly
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'>
											{' '}
											Shifting Charges{' '}
										</div>
										<div className='fleft width7 pad_t5'> &nbsp; </div>
										<div className='fleft width7'> &nbsp;</div>
										<div className='fleft width15 textcenter'> &nbsp; </div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9734].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'> &nbsp; </div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9735].value}
												readOnly
											/>
										</div>
									</div>
									<div className='check over_hid pad_t7'>
										<div className='fleft width170px pad_t3'>
											{' '}
											CPE Replacement Charges
										</div>
										<div className='fleft width7 pad_t5'> &nbsp; </div>
										<div className='fleft width7'> &nbsp;</div>
										<div className='fleft width15 textcenter'> &nbsp; </div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9736].value}
												readOnly
											/>
										</div>
										<div className='fleft width15 textcenter'> &nbsp; </div>
										<div className='fleft width15 textcenter'>
											<input
												className='form_col width75'
												type='text'
												name=''
												value={illCrfDataupdated[9737].value}
												readOnly
											/>
										</div>
									</div>
								</div>
								<br />
								<br />
								<br />
								<br />
								...
								<br />
								<br />
								<div className='signate float_img'>
									{firstCaps !== '' ? (
										<div style={{ padding: '10px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData !== null && imageData !== '' ? (
										<div style={{ padding: '10px' }} className='signate_img'>
											<img
												src={
													imageData != null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
								<br />
							</div>
						</div>
					</page>
					{!!queueData &&
					getVersionTerms(queueData?.activity_datetime_created || '') ===
						TERMS_VERSION.NEW_TERMS ? (
						<TermsConditionNew
							isCrf={true}
							firstCaps={firstCaps}
							Signcss={Signcss}
							secondBold={secondBold}
							imageData={imageData}
							imageType={imageType}
							CustomComponent={CustomComponent}
						/>
					) : (
						''
					)}
					<page size='A4'>
						<div className='section2'>
							<div className='font8'>
								<p className='textcenter font15 margin0 pad_t10 pad_b5 hed5'>
									COMMENTS
								</p>
								<div className='cmt_spl'>
									{' '}
									Comments/ Special Considerations:{' '}
									{illCrfDataupdated[9739].value}{' '}
								</div>
								<div className='over_hid'>
									<div className='fleft width65 pad_t10'>
										<p className='margin0'>
											<strong>Declaration</strong>
										</p>
										<p className='margin0'>
											I / We confirm having received, read and understood the
											Product Terms &amp; Conditions (provided overleaf) and the
											General Terms &amp; Conditions. I / We further confirm
											that the tariff plan selected and applicable rates form
											part of this Agreement (as defined herein) and I / We
											agree to abide by the Applicable Law in force and also any
											statutory amendments, or new legistlations as may be
											enacted from time to time, in so far as they realte to the
											services. I / We hereby declare and confirm that the above
											information provided by us is true and correct in all
											respects and I / We hereby undertake to be bound by the
											same.{' '}
										</p>
										<p className='margin0 pad_5t'>
											Authorised Signatory's Name
										</p>
										<p className='margin0'>
											<input
												className='form_col width98per cmnt_input'
												type='text'
												name=''
												value={illCrfDataupdated[9742].value}
												readOnly
											/>
										</p>
										<div className='over_hid pad_t15'>
											<div className='fleft width30'>
												<p className='margin0'>Designation</p>
												<p className='margin0'>
													<input
														className='form_col widthfull cmnt_input'
														type='text'
														name=''
														value={illCrfDataupdated[9743].value}
														readOnly
													/>
												</p>
											</div>
											<div className='fleft width30 mar_l20'>
												<p className='margin0'>Place</p>
												<p className='margin0'>
													<input
														className='form_col widthfull cmnt_input'
														type='text'
														name=''
														value={illCrfDataupdated[9744].value}
														readOnly
													/>
												</p>
											</div>
											<div className='fleft width30 mar_l20'>
												<p className='margin0'>Date</p>
												<p className='margin0'>
													<input
														className='form_col width96per cmnt_input'
														type='text'
														name=''
														value={illCrfDataupdated[9745].value}
														readOnly
													/>
												</p>
											</div>
										</div>
										<p className='margin0 pad_5t'>Account Manager Name</p>
										<div className='signate float_img'>
											{imageData != null && imageData !== '' ? (
												<div
													style={{ padding: '10px' }}
													className='signate_img'
												>
													<img
														src={
															imageData != null
																? 'data:' +
																  imageType +
																  ';base64,' +
																  encode(imageData)
																: ''
														}
														alt={'Company Seal'}
													/>
												</div>
											) : (
												''
											)}
										</div>
										<p className='margin0'>
											<input
												className='form_col width98per cmnt_input'
												type='text'
												name=''
												value={illCrfDataupdated[9747].value}
												readOnly
											/>
										</p>
										<p className='margin0 pad_20t'>
											Account Manager Circle Office
										</p>
										<p className='margin0'>
											<input
												className='form_col  wid120p cmnt_input'
												type='text'
												name=''
												value={illCrfDataupdated[9748].value}
												readOnly
											/>
										</p>
										<div className='pad_20t'>
											<div className='fleft width50'>
												<p className='margin0'>Channel Partner Name</p>
												<p className='margin0'>
													<input
														className='form_col widthfull cmnt_input'
														type='text'
														name=''
														value={illCrfDataupdated[9750].value}
														readOnly
													/>
												</p>
											</div>
											<div className='flright width30'>
												<p className='margin0'>Channel Partner Code</p>
												<p className='margin0'>
													<input
														className='form_col width92per cmnt_input'
														type='text'
														name=''
														value={illCrfDataupdated[9751].value}
														readOnly
													/>
												</p>
											</div>
										</div>
									</div>
									<div className='flright width30'>
										<div
											style={{
												padding: '5px',
												lineHeight: 'normal',
												width: '214px',
											}}
											className='signate'
										>
											{firstCaps !== '' ? (
												<div className={Signcss}>
													{firstCaps + '  .  '}
													<span className='under_line'>{secondBold}</span>
												</div>
											) : (
												''
											)}
											<div>
												Signature of the Customer / Authorised signatory with in
												this box only (In case of institutinal, please affix
												office/company seal){' '}
											</div>
										</div>
										<div
											style={{
												padding: '5px',
												height: '120px',
												marginTop: '140px',
												width: '216px',
												alignItems: 'center',
												display: 'flex',
											}}
											className='signate'
										>
											{acctMngSignImageData !== null &&
											acctMngSignImageData !== '' ? (
												<img
													height='90px'
													width='100px'
													src={
														acctMngSignImageData != null
															? 'data:' +
															  acctMngSignImageType +
															  ';base64,' +
															  encode(acctMngSignImageData)
															: ''
													}
													alt={'Signature of Account Manager'}
												/>
											) : (
												''
											)}
											<div>Signature of the Account Manager </div>
										</div>
									</div>
								</div>
								<div className='width80'>
									<p className='textcenter'>
										<strong>
											Vodafone Idea Limited (formerly Idea Cellular Limited) An
											Aditya Birla Group and Vodafone Partnership Merger Co CIN
											No. L32100GJ1996PLC030976Registered Office: Suman Tower,
											Plot No. 18 Sector 11, Gandhinagar-382011, Gujarat.
										</strong>
									</p>
								</div>
							</div>
						</div>
					</page>
					{excelJson !== null ? (
						<page size='A4'>
							<div>
								<div className='pad_t10 section2'>
									<p className='customfom'>Annexure to ILL CRF :- </p>
								</div>
								<JsonToTable json={excelJson} />
								<div>
									<p class='font10 ' style={{ marginTop: '20px' }}>
										<strong>Note:</strong> Address proofs will be required for
										each of the sites listed above as part of standard
										documentation guidelines; Master CAF referred here should be
										signed by the same
									</p>
								</div>

								<div class='fleft width50 pad_t10'>
									<p class='marb5'>Account Manager Name</p>
									<p class='margin0'>
										<input
											class='form_col widthfull inputext'
											type='text'
											name='Account Manager Name'
											readonly=''
											value={illCrfDataupdated[9747].value}
										/>
									</p>
									<div
										className='signate'
										style={{
											position: 'relative',
											padding: 5,
											overflow: 'hidden',
										}}
									>
										<div className='signate_img input_sign'>
											{acctMngSignImageData !== null &&
											acctMngSignImageData !== '' ? (
												<img
													src={
														acctMngSignImageData != null
															? 'data:' +
															  imageType +
															  ';base64,' +
															  encode(acctMngSignImageData)
															: ''
													}
													alt={'Account Manager Sign'}
												/>
											) : (
												''
											)}
										</div>
										<div>Signature of the Account Manager </div>
									</div>
								</div>
								<div
									className='signate float_img'
									style={{ position: 'initial' }}
								>
									<div className='over_hid '>
										<div className='over_hid '>
											Authorised Signatory Name
											{
												<input
													className='form_col inputext'
													type='text'
													name='Name_CD'
													value={illCrfDataupdated[9742].value}
													readOnly
												/>
											}
										</div>
									</div>
									{firstCaps !== '' ? (
										<div style={{ padding: '10px' }} className={Signcss}>
											{firstCaps + '  .  '}
											<span className='under_line'>{secondBold}</span>
										</div>
									) : (
										''
									)}
									{imageData !== null && imageData !== '' ? (
										<div style={{ padding: '10px' }} className='signate_img'>
											<img
												src={
													imageData != null
														? 'data:' +
														  imageType +
														  ';base64,' +
														  encode(imageData)
														: ''
												}
												alt={'Company Seal'}
											/>
										</div>
									) : (
										''
									)}
								</div>
							</div>
						</page>
					) : (
						''
					)}
				</div>
			</div>
		)
	} else {
		crfContentView = CrfData ? (
			<div>
				<Center p={2} my={1}>
					<Spinner
						thickness='4px'
						speed='0.65s'
						emptyColor={chakraTheme.colors.secondary}
						color={chakraTheme.colors.brand[800]}
						size='md'
					/>
				</Center>
			</div>
		) : (
			<div style={{ marginTop: '200px', height: '300px', textAlign: 'center' }}>
				{' '}
				ILL CRF Form not submitted yet.
			</div>
		)
	}
	return <div>{crfContentView}</div>
}

export default IllCrfForm
